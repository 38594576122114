import GhostContentAPI from '@tryghost/content-api';
import axios from 'axios';

import { marketplaceAddressPolygon, pinataLink, RPC_URLPolygon } from "../config/ethereum-config";

// Web3
import Web3 from "web3";

// ABI's
import MarketplaceJSON from '../contract/Marketplace.json';

// ethereum config
import { marketplaceAddress, RPC_URL } from '../config/ethereum-config';

const api = new GhostContentAPI({
    url: 'https://upyo.ghost.io',
    key: '40efc8d531da9931614f359b76',
    version: "v3"
});

// let url = "https://5txr4zhch4.execute-api.me-south-1.amazonaws.com/dev/";
let url = "https://d0ef2n19qd.execute-api.us-east-2.amazonaws.com/dev/";

const blockChainurl = process.env.blockChainurl; //don't comment this line   
const newsBlockchainurl = process.env.newsBlockchainurl;

const blockChainurlImportCollection = process.env.blockChainApiUrlImportCollection;

// api calling function
export const apiHelper = (api, method, data, headers) => {
    const apiUrl = url + api;
    return new Promise((resolve, reject) => {
        axios({
            method: method,
            url: apiUrl,
            data: data,
            // headers: headers
        })
            .then(res => resolve(res))
            .catch(error => {
                if (error.response && error.response.status === 401) {
                    localStorage.removeItem('token')
                } else {
                    reject(error);
                }
            });
    });
}
export const getPriceOfNft = async (collection_id, token_id, chainType) => {
    const rpcUrl = chainType === 'polygon' ? RPC_URLPolygon : RPC_URL
    const web3 = new Web3(new Web3.providers.HttpProvider(rpcUrl));

    let marketAddress = chainType === "polygon" ? marketplaceAddressPolygon : marketplaceAddress;

    const marketplaceContract = new web3.eth.Contract(MarketplaceJSON.abi, marketAddress);
    try {
        const auctions = await marketplaceContract.methods.auctions(collection_id, token_id).call();
        return auctions;
    } catch (error) {
        console.log(error, "error in getting auction");
        return error
    }
    
}

export const apiHelperBlockchain = (api, method, data, headers, isNews=false) => {
    const apiUrl = (isNews ? newsBlockchainurl : blockChainurl) + api;
    const options = headers ?
        {
            method: method,
            url: apiUrl,
            data: data,
            headers: headers
        } : {
            method: method,
            url: apiUrl,
            data: data,
            // headers: headers
        }
    return new Promise((resolve, reject) => {
        axios(options)
            .then(res => resolve(res))
            .catch(error => {
                if (error.response && error.response.status === 401) {
                    localStorage.removeItem('token')
                    // Router.push('/nft/login');
                } else {
                    reject(error);
                }
            });
    });
}

export const apiHelperEns = (api, method, data, headers) => {
    const apiUrl = process.env.EnsApiurl + api;
    const options = headers ?
        {
            method: method,
            url: apiUrl,
            data: data,
            headers: headers
        } : {
            method: method,
            url: apiUrl,
            data: data,
            // headers: headers
        }
    return new Promise((resolve, reject) => {
        axios(options)
            .then(res => resolve(res))
            .catch(error => {
                if (error.response && error.response.status === 401) {
                    localStorage.removeItem('token')
                } else {
                    reject(error);
                }
            });
    });
}

export const apiHelperBlockchainImportCollection = (api, method, data, headers) => {
    const apiUrl = blockChainurlImportCollection + api;
    return new Promise((resolve, reject) => {
        axios({
            method: method,
            url: apiUrl,
            data: data
        })
            .then(res => resolve(res))
            .catch(error => {
                if (error.response && error.response.status === 401) {
                    localStorage.removeItem('token')
                    // Router.push('/nft/login');
                } else {
                    reject(error);
                }
            });
    });
}

// api calling function using Pinata
export const pinataApiHelper = (cid) => {
    const apiUrl = pinataLink + cid;
    return new Promise((resolve, reject) => {
        axios({
            method: "GET",
            url: apiUrl
        }).then(res => resolve(res))
            .catch(error => {
                console.log("Error...", error);
            });
    });
}

export async function apiBrowser(query) {

    return await api.posts
        .browse(query)
        .catch((err) => {
            console.error(err);
        });

}
export async function apiReader(query) {

    return await api.posts
        .read(query)
        .catch((err) => {
            console.error(err);
        });

}

export async function settingBrowser() {

    return await api.settings
        .browse()
        .catch((err) => {
            //   console.error(err);
        });

}

export const checkAuthentication = () => {
    if (localStorage.getItem("walletAddress")) {
        if (localStorage.getItem("walletAddress") !== '') {
            return true;
        }
    }
    return false;
}

export const apiHelperWordpressForNews = (data) => {
    // const apiUrl = 'http://admin_blog.upyo.io';
    const apiUrl = 'http://3.111.63.208/graphql';
    return new Promise((resolve, reject) => {
        axios({
            method: 'post',
            url: apiUrl,
            data: {
                query: data
            },
            headers: {
                "Content-Type": "application/json"
            },
            auth: {
                username: 'admin',
                password: 'h8spjEWAnkEGsmkLQQm4'
            }
        })
            .then(res => {
                resolve(res)
            })
            .catch(error => {
                reject(error);
            });
    });
}

export const apiHelperWordpressForBlog = (data) => {
    const apiUrl = 'http://3.111.63.208/graphql';
    return new Promise((resolve, reject) => {
        axios({
            method: 'post',
            url: apiUrl,
            data: {
                query: data
            },
            headers: {
                "Content-Type": "application/json"
            },
            auth: {
                username: 'admin',
                password: 'h8spjEWAnkEGsmkLQQm4'
            }
        })
            .then(res => {
                resolve(res)
            })
            .catch(error => {
                reject(error);
            });
    });
}

export const thegraph = (domains) => {

    return new Promise((resolve, reject) => {
        axios.post('https://api.thegraph.com/subgraphs/name/ensdomains/ens',
            {
                query: `{
                registrations (where: {labelName: "${domains}"}) {
                  cost
                  domain {
                    name
                    owner {
                      id
                    }
                  }
                  registrationDate
                  expiryDate
                }
              }`
            })
            .then(res => resolve(res))
            .catch(error => {
                reject(error);
            });
    });

}

export const getUsdValue = async(chain) =>{
    try {
        const cryptoPrice = await axios({
        method: "GET",
        url: `https://api.coinbase.com/v2/exchange-rates?currency=${chain}`,
        });
    
        if (cryptoPrice && cryptoPrice.data) {
        return { usd: cryptoPrice.data.data.rates.USD };
        }
    
        if(chain === `eth`){
            return {usd : 1215.39}
        }
        else{
            return {usd : 0.89}
        }
    } catch (error) {
        console.log(error);
        if(chain === `eth`){
            return {usd : 1215.39}
        }
        else{
            return {usd : 0.89}
        }
    }
}